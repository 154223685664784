/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.drs-header {
    background-color: var(--ion-color-primary);
}

    .main-content {
        padding: 10px;
        
        @media (min-width: 820px) {
            padding: 20px 60px;
        }
    }
.rounder {
    position: absolute;
    width: var(--ion-rounder-dimension);
    height: var(--ion-rounder-dimension);
    bottom: calc((var(--ion-rounder-dimension) / 2) * -1);
    left: calc((var(--ion-rounder-dimension) / 2) * -1);
    background-color: var(--ion-color-primary);
    &:after {
        content: ' ';
        position: absolute;
        width: var(--ion-rounder-dimension);
        height: var(--ion-rounder-dimension);
        background-color: white;
        bottom: calc((var(--ion-rounder-dimension) / 2) * -1);
        left: calc(var(--ion-rounder-dimension) / 2);
        border-radius: 100%;
    }
}
.toastMultiLine {
    white-space : pre-wrap !important;
}

ion-menu.menu-side-start {
    --ion-background-color: var(--ion-color-primary);
    width: 100%;
    ion-title, ion-toolbar {
        --background: var(--ion-color-primary);
    }
}
ion-menu ion-chip {
    --ion-background-color: var(--ion-color-primary);
}
ion-toolbar ion-title {
  font-family: 'AppFontSuper';
}
.main-content ion-card-title {
  font-size: 20px;
  
  @media (min-width: 820px) {
    font-size: 22px;
  }
}
.semi-big-txt {
  color: #515151;
  font-weight: 500;
  letter-spacing: .1px;
  font-family: 'AppFontSuper';
  font-size: 1.1em;
}
.body {
  color: #515151;
  font-weight: 500;
  // letter-spacing: .1px;
  // font-family: 'AppFontSuper';
  font-size: 0.9em;
}
.body-bold {
  color: #515151;
  font-weight: 700;
  // letter-spacing: .1px;
  // font-family: 'AppFontSuper';
  font-size: 0.9em;
}
ion-button {
  text-transform: none;
  font-size: 1.1em;
  font-family: 'AppFontSuper';
  font-weight: 500;
}
.card-date-days {
  ion-row {
  padding-bottom: 5px;
  border-bottom: 1px solid #E7E7E7;
  }
  h6 {
    font-size: 16px;
    color: #515151;
    font-weight: 500;
    letter-spacing: .1px;
    font-family: 'AppFontSuper';
    &.font-14 {
      font-size: 14px;
      color: #7F7F7F;
    }
  }
}
.pull-up-10 {
  margin-top: -10px;
}
.view-more {
  margin-right: 20px;
  color:#979797;
  font-weight: 900;
  text-decoration: none;
}
.pad-10 {
  padding-bottom: 10px;
  display: inline-block;
}
ion-badge {
  font-family: 'AppFontSuper';
  font-weight: 500;
  text-transform: uppercase !important;
  font-size: 12px;
}

.reg-lines {
  margin-top: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #E7E7E7;
}
.reg-lines:last-child {
  border-bottom: 0 none;
}
.pickup-subheadline {
  color: #515151 !important;
  font-family: 'AppFontSuper';
}
.smallDate {
  // font-size: 1.1em;
  font-size: 3.6vw;
  color: #7C7C7C;
  @media (min-width: 820px) {
      font-size: 1.3em;
  }
}
.smallGtin {
  // font-size: 1em;
  font-size: 3.5vw;
  @media (min-width: 820px) {
      font-size: 1.2em;
  }
  // color: #7C7C7C;
}
.margin-regs {
  margin-top: 2px;
  margin-bottom: 2px;
}
.left-pad {
  padding-left: 10px;
}

// accordion BEG
ion-card-title {
    font-weight: 500;
    color: #333333;
    letter-spacing: .1px;
    font-family: 'AppFontSuper';
  }
  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: none;
    color: #000;
    font-weight: normal;
    font-size: 1.1em;
  }
  .small-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: none;
    color: #000;
    font-weight: normal;
    font-size: 0.9em;
  }
  .card-content-ios {
    padding: 5px;
  }
  .icon-empty {
    background-image: url("./assets/empty.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    padding-left: 32px;
    background-position: center left;
    margin-bottom: 16px;
  }
  .icon-collect {
    background-image: url("./assets/pickup.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    padding-left: 32px;
    background-position: center left;
    margin-bottom: 16px;
  }
  .icon-empty-grey {
    background-image: url("./assets/empty_grey.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    padding-left: 32px;
    background-position: center left;
    margin-bottom: 16px;
  }
  .icon-collect-grey {
    background-image: url("./assets/pickup_grey.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    padding-left: 32px;
    background-position: center left;
    margin-bottom: 16px;
  }
  .icon-history-warning {
    background-image: url("./assets/icons/info.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    padding-left: 32px;
    background-position: center left;
    margin-bottom: 16px;
  }
  .trash-icon {
    background-image: url("./assets/trash-outline.svg");
    background-repeat: no-repeat;
    background-size: 16px 16px;
    width: 16px;
    height: 21px;
    display: inline-block;
    margin-top: 0;
    background-position: center center;
    cursor: pointer;
    margin-right: 6px;
  }
  .cursor {
    cursor: pointer;
  }
  .v-align {
    vertical-align: super;
    padding-right: 6px; 
  }
  .lines-bottom {
    border-bottom: 1px solid #DBDBDB;
    padding: 10px;
  }
  .link-btn {
    font-family: 'AppFontSuper';
    margin-right: 15px;
    margin-left: 15px;
    font-weight: 500;
    font-size: 1.1em;
    letter-spacing: .1px;
  }

//   *,
// *:before,
// *:after {
// 	box-sizing: inherit;
// }
.barcode-scanning-modal.lowerZindex {
  z-index: 1 !important;
}

.appVersion-txt {
  font-size: 0.8em;
  padding-bottom: 2px;
  color: #ffffff;
}


$details-padding: 0.5em;
ion-card-header {
  padding: 10px;
}

  details {
    position: relative;

    summary {
      list-style: none;
      padding-right: $details-padding;
  
      &:focus {
        outline: none;
      }
  
      &:hover .summary-chevron-up svg {
        opacity: 1;
      }
      ion-card-header {
        padding-right: 20px;
      }
    }
  
    .summary-chevron-up svg {
      opacity: 0.5;
    }
  
    .summary-chevron-up,
    .summary-chevron-down {
      pointer-events: none;
      position: absolute;
      top: 10px;
      // bottom: 0;
      // height: 24px;
      margin: auto;
      right: $details-padding;
      background: #ffffff;
  
      svg {
        display: block;
      }
    }
  
    summary::-webkit-details-marker {
      display: none;
    }
  }
// accordion END

.d-side-menu {
  .summary-chevron-up,
  .summary-chevron-down {
    pointer-events: none;
    position: absolute;
    top: 4px;
    // bottom: 0;
    // height: 24px;
    margin: auto;
    right: $details-padding;
    background: var(--ion-color-primary);

    svg {
      display: block;
    }
  }
}



// checkbox
ion-checkbox {
  --size: 22px;
  --checkbox-background-checked: #6B7A83;
  // --ion-text-color: #000;
}

ion-checkbox::part(container) {
  border-radius: 2px;
  border: 2px solid #6B7A83;
}

h1, h2, h3, h4, h5 {
  font-family: 'AppFontSuper';
  letter-spacing: .1px;
  color: #333333;
}
